import React from 'react'
import Page from '../components/Page/Page.jsx'

const PageNotFound = () => (
  <Page>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </Page>
)

export default PageNotFound
